import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// Atoms
import Paragraph from "../../components/atoms/paragraph";
import Button from "../../components/atoms/button";
import Link from "../../components/atoms/link";
import Title from "../../components/atoms/title";
import Top from "../../components/templates/top";
import Image from "../../components/atoms/image";
// Templates
import BottomSheetMessage from "../../components/templates/bottom-sheet-message";

import MessagesReactModal from "../../components/templates/messages-react-modal";
// Molecules
import Input from "../../components/molecules/input";
import TitleAndImage from "../../components/molecules/title-and-image";
import Select from "../../components/molecules/select";
import InputGroupAction from "../../components/molecules/input-group-action";
// Hooks
import { useApi } from "../../hooks/useApi";
import { useFederalEntity } from "../../hooks/useFederalEntity";
import { useZipCode } from "../../hooks/useZipCode";
import { useFollowLater } from "../../hooks/useFollowLater";
// Redux
import { store } from "../../redux/store";
import {
  addConfirmData,
  credentialData,
  principalAddress,
} from "../../redux/slices/confirmDataReducer";
// Services
import PersonalDataService from "../../services/personalData.service";
import UtilsCommonsService from "../../services/utilsCommons.service";
// Use-Cases
import { DataINEuseCase } from "../../../application/use-cases/dataINE.usecase";
// Assets
import IneModel from "../../assets/images/ine-model.png";
import IneGhType from "../../assets/images/ine-gh-type.png";
import InfoIcon from "../../assets/icons/circle-info-sharp-regular.svg";
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
import ic_xclose from "../../assets/icons/ic_xclose.svg";
import redCircleExclamationSharpSolid from "../../assets/icons/red-circle-exclamation-sharp-solid.svg";
// Interfaces
import { ICatalog } from "../../interfaces/api/IUtilsCommons";
// Dictionaries
import listText from "../../utils/dictionaries/dictionary-data-confirmation";
// Utils
import { days, months, years, genders, federalEntity } from "../../utils/utils";
import { allownumber } from "../../utils/patterns";
// Amplitude
import { trackAnalyticsEvent } from "../../utils/amplitude";
import { AmplitudEventName } from "../../utils/amplitude/types";
import { Identify, identify } from "@amplitude/analytics-browser";

const DataConfirmation = () => {
  const { onClickContinue } = useFollowLater();
  const { getNationality } = new UtilsCommonsService();
  const { getCountries } = new UtilsCommonsService();

  const postConfirmData = new PersonalDataService();
  const useCasePostConfirmData = new DataINEuseCase(postConfirmData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const states = store.getState();
  const [isOpenBottonSheet, setOpenBottonSheet] = useState(false);
  const [openInfoButton, setOpenInfoButton] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [suburbss, setSuburbss] = useState<any>();
  const [invalidDataBpId, setInvalidDataBpId] = useState<boolean>(false);
  const { data: dataGetNationality = [], execute: executeGetNationality } = useApi<
    ICatalog[],
    { processId: string }
  >(getNationality);

  const { data: dataGetCountries = [], execute: executeGetCountries } = useApi<
    ICatalog[],
    { processId: string }
  >(getCountries);

  const formik = useFormik({
    initialValues: {
      name1: states.client.confirmData.name1,
      name2: states.client.confirmData.name2,
      lastName1: states.client.confirmData.lastName1,
      lastName2: states.client.confirmData.lastName2,
      dateBirth: states.client.confirmData.dateBirth,
      day: isNaN(new Date(states.client.confirmData.dateBirth).getDate())
        ? ""
        : String(new Date(states.client.confirmData.dateBirth).getDate()).padStart(2, "0"),
      month: isNaN(new Date(states.client.confirmData.dateBirth).getMonth())
        ? ""
        : String(new Date(states.client.confirmData.dateBirth).getMonth() + 1).padStart(2, "0"),
      year: isNaN(new Date(states.client.confirmData.dateBirth).getFullYear())
        ? ""
        : new Date(states.client.confirmData.dateBirth).getFullYear(),
      gender: states.client.confirmData.gender,
      nationality: states.client.confirmData.nationality,
      curp: states.client.confirmData.curp,
      phone: states.client.confirmData?.phone,
      email: states.client.confirmData?.email,
      placeBirth: states.client.confirmData?.placeBirth,
      federalEntity: states.client.confirmData?.federalEntity,
      township: states.client.confirmData?.principalAddress?.township,
      townshipId: states.client.confirmData.principalAddress?.townshipId,
      zipCode: states.client.confirmData.principalAddress?.zipCode,
      state: states.client.confirmData.principalAddress.state || "",
      stateId: states.client.confirmData.principalAddress.stateId,
      stateCRM: states.client.confirmData.principalAddress.stateCRM,
      suburbs: states.client.confirmData.principalAddress.suburbs,
      street: states.client.confirmData.principalAddress?.street,
      externalNumber: states.client.confirmData.principalAddress?.externalNumber,
      internalNumber: "",
      country: states.client.confirmData.principalAddress?.country,
      electorKey: states.client.confirmData.credentialData?.electorKey,
      registerYear: states.client.confirmData?.credentialData.registerYear,
      emissionYear: states.client.confirmData?.credentialData.emissionYear,
      ocr: states.client.confirmData.credentialData?.ocr,
      cic: states.client.confirmData.credentialData?.cic,
    },
    onSubmit: (values) => console.log(values),
    validationSchema: yup.object({
      name1: yup.string().required("Campo requerido"),
      lastName1: yup.string().required("Campo requerido"),
      lastName2: yup.string().required("Campo requerido"),
      curp: yup
        .string()
        .required("Campo requerido")
        .matches(
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
          "CURP inválido",
        ),
      street: yup.string().required("Campo requerido"),
      externalNumber: yup.string().required("Campo requerido"),
      ocr: yup.string().required("Campo requerido").length(13, "Debe contener 13 dígitos"),
      cic: yup.string().required("Campo requerido").length(9, "Debe contener 9 dígitos"),
      phone: yup.string(),
      email: yup.string(),
      gender: yup.string().required("Campo requerido"),
      electorKey: yup
        .string()
        .required("Campo requerido")
        .length(18, "Debe contener 18 caracteres"),
      zipCode: yup.string().required("Campo requerido").length(5, "Debe contener 5 dígitos"),
      registerYear: yup.string().required("Campo requerido").length(4, "Debe contener 4 dígitos"),
      emissionYear: yup.string().length(4, "Debe contener 4 dígitos"),
      suburbs: yup.string().required("Campo requerido"),
      state: yup.string().required("Campo requerido"),
      placeBirth: yup.string().required("Campo requerido"),
      nationality: yup.string().required("Campo requerido"),
      day: yup.number().required("Campo requerido"),
      month: yup.number().required("Campo requerido"),
      year: yup.number().required("Campo requerido"),
      federalEntity: yup.object({}).required("Campo requerido"),
    }),
  });

  const { dataGetFederalEntity, errorFederalEntity, isLoadingFederalEntity } = useFederalEntity({
    curp: formik.values.curp,
  });

  const { dataGetZipCode, errorZipCode, isLoadingGetZipCode } = useZipCode({
    postalCode: formik.values.zipCode || states.client.confirmData.principalAddress.zipCode,
  });

  useEffect(() => {
    executeGetNationality({ processId: states?.client?.clientPersist?.uuid });
    executeGetCountries({ processId: states?.client?.clientPersist?.uuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingFederalEntity && !errorFederalEntity) {
      formik.setFieldValue("federalEntity", dataGetFederalEntity);
    } else if (!isLoadingFederalEntity && errorFederalEntity) {
      formik.setFieldValue("federalEntity", dataGetFederalEntity);
    }

    if (dataGetZipCode[0]) {
      formik.setFieldValue("federalEntity", dataGetFederalEntity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFederalEntity, isLoadingFederalEntity]);

  useEffect(() => {
    if (!isLoadingGetZipCode && !errorZipCode) {
      formik.setFieldValue("state", dataGetZipCode[0]?.state);
      formik.setFieldValue("stateId", dataGetZipCode[0]?.stateId);
      formik.setFieldValue("stateCRM", dataGetZipCode[0]?.stateCRM);
      formik.setFieldValue("township", dataGetZipCode[0]?.township);
      formik.setFieldValue("townshipId", dataGetZipCode[0]?.townshipId);
    } else if (!isLoadingGetZipCode && errorZipCode) {
      formik.setFieldValue("state", "");
      formik.setFieldValue("stateId", "");
      formik.setFieldValue("stateCRM", "");
      formik.setFieldValue("township", "");
      formik.setFieldValue("townshipId", "");
    }
    if (dataGetZipCode[0]) {
      formik.setFieldValue("state", dataGetZipCode[0]?.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingGetZipCode, errorZipCode]);

  const [modalShow, setModalShow] = useState(false);

  const submit = async () => {
    setShowSpinner(true);
    const payload = {
      credentialData: {
        cic: formik.values?.cic,
        registerYear: formik.values?.registerYear,
        emissionYear: formik.values?.emissionYear,
        electorKey: formik.values?.electorKey,
        ocr: formik.values?.ocr,
      },
      curp: formik.values.curp,
      dateBirth: formik.values.year + "-" + formik.values.month + "-" + formik.values.day,
      email: formik.values.email,
      gender: formik.values.gender,
      lastName1: formik.values.lastName1,
      lastName2: formik.values.lastName2,
      name1: formik.values.name1,
      name2: formik.values.name2,
      nationality: formik.values.nationality,
      phone: formik.values.phone,
      placeBirth: formik.values.placeBirth,
      federalEntity: formik.values.federalEntity,
      principalAddress: {
        zipCode: formik.values.zipCode,
        state: formik.values.state,
        stateId: formik.values.stateId,
        stateCRM: formik.values.stateCRM,
        suburbs: formik.values.suburbs,
        street: formik.values.street,
        externalNumber: formik.values.externalNumber,
        internalNumber: formik.values.internalNumber,
        townshipId: formik.values.townshipId,
        township: formik.values.township,
        country: formik.values.country,
      },
      processId: states.client.clientPersist.uuid,
    };
    dispatch(addConfirmData(payload));
    dispatch(credentialData(payload.credentialData));
    dispatch(principalAddress(payload.principalAddress));
    const response = await useCasePostConfirmData.postConfirmData(
      payload,
      states.client.clientPersist.uuid,
    );

    if (!response.message) {
      if (response.data.errorCode === "400.n4_personal_data.14") {
        setInvalidDataBpId(true);
      }
      setModalShow(true);
    } else {
      onClickContinue({
        data: { confirmData: { ...payload }, dataPersist: { ...states.client.dataPersist } },
        processId: states.client.clientPersist.uuid,
        resource: "DataConfirmation",
        step: "CONTINUE",
      });
      trackAnalyticsEvent(
        AmplitudEventName.Confirmar_datos_personales_N4,
        {
          Fecha_de_nacimiento: payload.dateBirth,
          CURP: payload.curp,
          Codigo_postal: payload.principalAddress.zipCode,
          OCR: payload.credentialData.ocr,
          CIC: payload.credentialData.cic,
        }
      )
      const userProperties = new Identify();

      userProperties.set('Nombre Completo', `${payload.name1 || ''} ${payload.name2 || ''} ${payload.lastName1 || ''} ${payload.lastName2 || ''}`);
      userProperties.set('CURP', payload.curp);
      userProperties.set('Fecha de nacimiento', payload.dateBirth);
      identify(userProperties);

      navigate("/BlackLists");
    }
    setShowSpinner(false);
  };

  const functionProcessModal = () => {
    setModalShow(false);
  };

  const change = () => {
    if (formik.errors.zipCode || errorZipCode) {
      formik.setFieldValue("state", "");
      formik.setFieldValue("suburbs", "");
    }
  };

  const followLaterAction = () => {
    const payload = {
      credentialData: {
        cic: formik.values?.cic,
        registerYear: formik.values?.registerYear,
        emissionYear: formik.values?.emissionYear,
        electorKey: formik.values?.electorKey,
        ocr: formik.values?.ocr,
      },
      curp: formik.values.curp,
      dateBirth: formik.values.year + "-" + formik.values.month + "-" + formik.values.day,
      email: formik.values.email,
      gender: formik.values.gender,
      lastName1: formik.values.lastName1,
      lastName2: formik.values.lastName2,
      name1: formik.values.name1,
      name2: formik.values.name2,
      nationality: formik.values.nationality,
      phone: formik.values.phone,
      placeBirth: formik.values.placeBirth,
      federalEntity: formik.values.federalEntity,
      principalAddress: {
        zipCode: formik.values.zipCode,
        state: formik.values.state,
        stateId: formik.values.stateId,
        stateCRM: formik.values.stateCRM,
        suburbs: formik.values.suburbs,
        street: formik.values.street,
        externalNumber: formik.values.externalNumber,
        internalNumber: formik.values.internalNumber,
        townshipId: formik.values.townshipId,
        township: formik.values.township,
        country: formik.values.country,
      },
      processId: states.client.clientPersist.uuid,
    };
    dispatch(addConfirmData(payload));
    dispatch(credentialData(payload.credentialData));
    dispatch(principalAddress(payload.principalAddress));

    navigate("/FollowLater", {
      state: {
        processId: states.client.clientPersist.uuid,
        resource: "DataConfirmation",
        step: "CONTINUE_AFTER",
        data: { confirmData: { ...payload }, dataPersist: { ...states.client.dataPersist } }, // Agregar data del formulario
      },
    });
  };

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={listText.text_1.mainText}
          subTitle={listText.text_2.mainText}
          displayProgressBar={""}
          displayLeftImageTitle={"d-none"}
          classTitleImage={"m-2"}
          classHeader={"mt-2"}
          styleHeader={{}}
          classProgressBar={"mt-3"}
          styleProgressBar={{}}
          classTitleAndImage={""}
          styleTitleAndImage={{}}
          valueProgressBar={"45%"}
          comeFrom={"/DataConfirmation"}
          onClickGoBackIcon={() => navigate("/")}
        />

        <div className="p-3">
          <div className="my-2">
            <Paragraph
              text={listText.text_3.mainText}
              className={"fs-6"}
              styleParagraph={undefined}
            />
          </div>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id={listText.text_4.mainText}>
                <button
                  className="accordion-button left-auto"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${listText.text_4.mainText}`.replace(/\s+/g, "")}
                  aria-expanded="true"
                  aria-controls={`collapse${listText.text_4.mainText}`.replace(/\s+/g, "")}
                >
                  {formik.errors.name1 ||
                    formik.errors.lastName1 ||
                    formik.errors.lastName2 ||
                    formik.errors.curp ||
                    formik.errors.federalEntity ||
                    formik.errors.day ||
                    formik.errors.month ||
                    formik.errors.year ? (
                    <Image
                      srcImage={redCircleExclamationSharpSolid}
                      className={""}
                      alt={""}
                      dataBsDismiss={undefined}
                      styleImg={undefined}
                      onClick={undefined}
                    />
                  ) : (
                    ""
                  )}
                  <Title
                    text={listText.text_4.mainText}
                    className={"fw-bold"}
                    styleTitle={{ color: "#00376F" }}
                  />
                </button>
              </h2>
              <div
                id={`collapse${listText.text_4.mainText}`.replace(/\s+/g, "")}
                className={"accordion-collapse collapse show"}
                aria-labelledby={`collapse${listText.text_4.mainText}`.replace(/\s+/g, "")}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="row mb-4_3 mt-4_3">
                    <div className="col-12">
                      <Input
                        className={`form-control ${formik.errors.name1 ? "is-invalid" : ""}`}
                        type="text"
                        id="name1"
                        name="name1"
                        placeholder=""
                        value={formik.values.name1 || ""}
                        error={formik.errors.name1 || ""}
                        text="Primer nombre"
                        onChange={(e: any) =>
                          formik.setFieldValue("name1", e.target.value.toUpperCase())
                        }
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>

                  <div className="row mb-4_3">
                    <div className="col-12">
                      <Input
                        className={"form-control fs-6"}
                        type="text"
                        id="name2"
                        name="name2"
                        placeholder="N/A"
                        value={formik.values.name2 || ""}
                        error={formik.errors.name2}
                        text="Segundo nombre (Opcional)"
                        onChange={(e: any) =>
                          formik.setFieldValue("name2", e.target.value.toUpperCase())
                        }
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>

                  <div className="row mb-4_3">
                    <div className="col-12">
                      <Input
                        className={`form-control ${formik.errors.lastName1 ? "is-invalid" : ""}`}
                        type="text"
                        id="lastName1"
                        name="lastName1"
                        placeholder=""
                        value={formik.values.lastName1 || ""}
                        error={formik.errors.lastName1}
                        text="Apellido paterno"
                        onChange={(e: any) =>
                          formik.setFieldValue("lastName1", e.target.value.toUpperCase())
                        }
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>

                  <div className="row mb-4_3">
                    <div className="col-12">
                      <Input
                        className={`form-control ${formik.errors.lastName2 ? "is-invalid" : ""}`}
                        type="text"
                        id="lastName2"
                        name="lastName2"
                        placeholder=""
                        value={formik.values.lastName2 || ""}
                        error={formik.errors.lastName2}
                        text="Apellido materno"
                        onChange={(e: any) =>
                          formik.setFieldValue("lastName2", e.target.value.toUpperCase())
                        }
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 align-self-center">
                      <Paragraph
                        text={"Ingresa tu fecha y lugar de nacimiento"}
                        className={"fs-6 mb-3"}
                        styleParagraph={{ marginTop: "0px" }}
                      />
                    </div>
                  </div>

                  <div className="row mb-4_3 px-2 d-flex">
                    <div className="col-4 px-1">
                      <div className="form-floating">
                        <Select
                          className={`form-select py-0 ${formik.errors.day || dateError ? "is-invalid" : ""
                            }`}
                          value={formik.values.day}
                          placeholder="Día"
                          error={`${formik.errors.day || dateError ? "Fecha no válida" : ""}`}
                          data={days().map((data: any) => ({
                            value: data.value,
                            label: String(data.label).padStart(2, "0"),
                          }))}
                          onChange={(e: any) => {
                            formik.setFieldValue("day", e.target.value);
                            if (
                              (["30", "31"].includes(e.target.value) &&
                                String(formik.values.month) === "02") ||
                              (["04", "06", "09", "11"].includes(String(formik.values.month)) &&
                                e.target.value === "31")
                            ) {
                              setDateError(true);
                            } else {
                              setDateError(false);
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-4 px-1">
                      <div className="form-floating">
                        <Select
                          className={`form-select py-0 ${formik.errors.month || dateError ? "is-invalid" : ""
                            }`}
                          value={formik.values.month}
                          placeholder="Mes"
                          error={`${formik.errors.month || dateError ? "Fecha no válida" : ""}`}
                          data={months().map((data: any) => ({
                            value: data.value,
                            label: data.label,
                          }))}
                          onChange={(e: any) => {
                            formik.setFieldValue("month", e.target.value);
                            if (
                              (["30", "31"].includes(String(formik.values.day)) &&
                                e.target.value === "02") ||
                              (["04", "06", "09", "11"].includes(e.target.value) &&
                                String(formik.values.day) === "31")
                            ) {
                              setDateError(true);
                            } else {
                              setDateError(false);
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-4 px-1">
                      <div className="form-floating">
                        <Select
                          className={`form-select py-0 ${formik.errors.year ? "is-invalid" : ""}`}
                          value={formik.values.year}
                          placeholder="Año"
                          error={`${formik.errors.year ? "Fecha no válida" : ""}`}
                          data={years().map((data: any) => ({
                            value: data.value,
                            label: data.label,
                          }))}
                          onChange={(e: any) => formik.setFieldValue("year", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4_3 px-2">
                    <div className="col-12 px-1">
                      <div className="form-floating">
                        <Select
                          value={formik.values.gender}
                          placeholder="Seleccionar"
                          id="gender"
                          text="Género"
                          data={genders().map((data: any) => ({
                            value: data.value,
                            label: data.label,
                          }))}
                          onChange={(e: any) => formik.setFieldValue("gender", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4_3">
                    <div className="col-12">
                      <div className="form-floating">
                        <Select
                          value={formik.values.nationality}
                          placeholder="Seleccionar"
                          id="nationality"
                          text={listText.text_11.mainText}
                          data={dataGetNationality?.map((data: any) => ({
                            value: data.key,
                            label: data.description,
                          }))}
                          onChange={(e: any) => formik.setFieldValue("nationality", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4_3">
                    <div className="col-12">
                      <Input
                        className={`form-control ${formik.errors.curp ? "is-invalid" : ""}`}
                        type="text"
                        id="curp"
                        name="curp"
                        placeholder=""
                        value={formik.values.curp || ""}
                        error={formik.errors.curp}
                        text="CURP"
                        onChange={(e: any) =>
                          formik.setFieldValue("curp", e.target.value.toUpperCase())
                        }
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>

                  <div className="row mb-4_3">
                    <div className="col-12">
                      <div className="form-floating">
                        <Select
                          value={formik.values.placeBirth}
                          placeholder="Seleccionar"
                          text={listText.text_13.mainText}
                          data={dataGetCountries?.map((data: any) => ({
                            value: data.key,
                            label: data.description,
                          }))}
                          onChange={(e: any) => formik.setFieldValue("placeBirth", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4_3">
                    <div className="col-12">
                      <div className="form-floating">
                        <Select
                          className={`form-select py-0 ${formik.errors.federalEntity ? "is-invalid" : ""
                            }`}
                          value={formik.values.federalEntity?.key}
                          placeholder="Seleccionar"
                          id="federalEntity"
                          text="Entidad federativa de nacimiento"
                          error={`${formik.errors.federalEntity ? "Campo requerido" : ""}`}
                          data={federalEntity().map((data: any) => ({
                            value: data.value,
                            label: data.label,
                          }))}
                          onChange={(e: any) =>
                            formik.setFieldValue("federalEntity", e.target.value)
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id={listText.text_14.mainText}>
                <button
                  className="accordion-button left-auto"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${listText.text_14.mainText}`?.replace(/\s+/g, "")}
                  aria-expanded="true"
                  aria-controls={`collapse${listText.text_14.mainText}`?.replace(/\s+/g, "")}
                >
                  <Title
                    text={listText.text_14.mainText}
                    className={"fw-bold"}
                    styleTitle={{ color: "#00376F" }}
                  />
                </button>
              </h2>
              <div
                id={`collapse${listText.text_14.mainText}`?.replace(/\s+/g, "")}
                className={"accordion-collapse collapse collapsed"}
                aria-labelledby={`collapse${listText.text_14.mainText}`?.replace(/\s+/g, "")}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="row mb-0">
                    <div className="col-5 align-self-center">
                      <Paragraph
                        text={listText.text_15.mainText}
                        className={"fs-7 mb-0"}
                        styleParagraph={{ marginTop: "0px" }}
                      />
                    </div>
                    <div className="col-7">
                      <Input
                        className={
                          "form-control px-3 py-2 fs-7 border border-0 bg-transparent h-auto min-height-auto"
                        }
                        type="text"
                        id="phone"
                        name="phone"
                        placeholder=""
                        value={formik.values.phone || ""}
                        error={formik.errors.phone}
                        text=""
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={true}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5 align-self-center">
                      <Paragraph
                        text={listText.text_16.mainText}
                        className={"fs-7 mb-0"}
                        styleParagraph={{ marginTop: "0px" }}
                      />
                    </div>
                    <div className="col-7">
                      <Input
                        className={
                          "form-control px-3 py-2 fs-7 border border-0 bg-transparent h-auto min-height-auto"
                        }
                        type="email"
                        id="email"
                        name="email"
                        placeholder=""
                        value={formik.values.email || ""}
                        error={formik.errors.email}
                        text=""
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={true}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id={listText.text_17.mainText}>
                <button
                  className="accordion-button left-auto"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${listText.text_17.mainText}`?.replace(/\s+/g, "")}
                  aria-expanded="true"
                  aria-controls={`collapse${listText.text_17.mainText}`?.replace(/\s+/g, "")}
                >
                  {formik.errors.zipCode ||
                    formik.errors.street ||
                    formik.errors.externalNumber ||
                    formik.errors.state ||
                    formik.errors.suburbs ||
                    formik.errors.country ? (
                    <Image
                      srcImage={redCircleExclamationSharpSolid}
                      className={""}
                      alt={""}
                      dataBsDismiss={undefined}
                      styleImg={undefined}
                      onClick={undefined}
                    />
                  ) : (
                    ""
                  )}
                  <Title
                    text={listText.text_17.mainText}
                    className={"fw-bold"}
                    styleTitle={{ color: "#00376F" }}
                  />
                </button>
              </h2>
              <div
                id={`collapse${listText.text_17.mainText}`?.replace(/\s+/g, "")}
                className={"accordion-collapse collapse collapsed"}
                aria-labelledby={`collapse${listText.text_17.mainText}`?.replace(/\s+/g, "")}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="row mb-4_3">
                    <div className="col-12">
                      <div className="form-floating">
                        <Select
                          value={formik.values.country}
                          placeholder="Seleccionar"
                          id="country"
                          text={listText.text_18.mainText}
                          data={dataGetCountries?.map((data: any) => ({
                            value: data.key,
                            label: data.description,
                          }))}
                          onChange={(e: any) => formik.setFieldValue("country", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4_3">
                    <div className="col-12">
                      <Input
                        className={`form-control fs-6 ${formik.errors.zipCode || errorZipCode ? "is-invalid" : ""
                          }`}
                        type="text"
                        id="zipCode"
                        name="zipCode"
                        placeholder=""
                        value={formik.values.zipCode}
                        error={
                          formik.errors.zipCode || (errorZipCode ? "Código postal no válido" : "")
                        }
                        text={listText.text_19.mainText}
                        onChange={(e: any) => {
                          formik.setFieldValue("zipCode", e.target.value);
                          change();
                        }}
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>

                  <div className="row mb-4_3 px-2">
                    <div className="col-12 px-1">
                      <div className="form-floating">
                        <Select
                          className={formik.errors.state || errorZipCode ? "is-invalid" : ""}
                          value={formik.values.state}
                          placeholder="Seleccionar"
                          id="state"
                          text={listText.text_20.mainText}
                          error={errorZipCode || formik.errors.zipCode ? "Campo requerido" : ""}
                          data={dataGetZipCode?.map((data: any) => ({
                            value: data.state,
                            label: data.state,
                          }))}
                          onChange={(e: any) => formik.setFieldValue("state", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4_3 px-2">
                    <div className="col-12 px-1">
                      <div className="form-floating">
                        <Select
                          className={formik.errors.suburbs || errorZipCode ? "is-invalid" : ""}
                          value={formik.values.suburbs}
                          placeholder="Seleccionar"
                          id="suburbs"
                          text={listText.text_21.mainText}
                          error={errorZipCode || formik.errors.zipCode ? "Campo requerido" : ""}
                          data={
                            dataGetZipCode.length
                              ? dataGetZipCode[0]?.suburbs.map((key) => ({
                                label: key,
                                value: key,
                              }))
                              : []
                          }
                          onChange={(e: any) => formik.setFieldValue("suburbs", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4_3">
                    <div className="col-12">
                      <Input
                        className={`form-control ${formik.errors.street ? "is-invalid" : ""}`}
                        type="text"
                        id="street"
                        name="street"
                        placeholder=""
                        value={formik.values.street || ""}
                        error={formik.errors.street}
                        text={listText.text_22.mainText}
                        onChange={(e: any) => {
                          formik.setFieldValue("street", e.target.value.toUpperCase());
                        }}
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>

                  <div className="row mb-4_3">
                    <div className="col-12">
                      <Input
                        className={`form-control ${formik.errors.externalNumber ? "is-invalid" : ""
                          }`}
                        type="text"
                        id="externalNumber"
                        name="externalNumber"
                        placeholder=""
                        value={formik.values.externalNumber || ""}
                        error={formik.errors.externalNumber}
                        text={listText.text_23.mainText}
                        onChange={(e: any) => {
                          formik.setFieldValue("externalNumber", e.target.value.toUpperCase());
                        }}
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item text-left">
              <h2 className="accordion-header" id={listText.text_24.mainText}>
                <button
                  className="accordion-button left-auto"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${listText.text_24.mainText}`?.replace(/\s+/g, "")}
                  aria-expanded="true"
                  aria-controls={`collapse${listText.text_24.mainText}`?.replace(/\s+/g, "")}
                >
                  {formik.errors.electorKey ||
                    formik.errors.registerYear ||
                    formik.errors.emissionYear ||
                    formik.errors.ocr ||
                    formik.errors.cic ? (
                    <Image
                      srcImage={redCircleExclamationSharpSolid}
                      className={""}
                      alt={""}
                      dataBsDismiss={undefined}
                      styleImg={undefined}
                      onClick={undefined}
                    />
                  ) : (
                    ""
                  )}
                  <Title
                    text={listText.text_24.mainText}
                    className={"fw-bold"}
                    styleTitle={{ color: "#00376F" }}
                  />
                </button>
              </h2>
              <div
                id={`collapse${listText.text_24.mainText}`?.replace(/\s+/g, "")}
                className={"accordion-collapse collapse collapsed"}
                aria-labelledby={`collapse${listText.text_24.mainText}`?.replace(/\s+/g, "")}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="row mb-4_3">
                    <div className="col-12">
                      <Input
                        className={`form-control ${formik.errors.electorKey ? "is-invalid" : ""}`}
                        type="text"
                        id="electorKey"
                        name="electorKey"
                        placeholder=""
                        value={formik.values.electorKey || ""}
                        error={formik.errors.electorKey}
                        text={listText.text_25.mainText}
                        onChange={(e: any) => {
                          formik.setFieldValue("electorKey", e.target.value.toUpperCase());
                        }}
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>

                  <div className="row mb-4_3">
                    <div className="col-12">
                      <Input
                        className={`form-control ${formik.errors.registerYear ? "is-invalid" : ""}`}
                        type="tel"
                        id="registerYear"
                        name="registerYear"
                        placeholder=""
                        value={formik.values.registerYear || ""}
                        error={formik.errors.registerYear}
                        text={listText.text_26.mainText}
                        onChange={(e: any) => {
                          if (allownumber.test(e.target.value)) {
                            formik.setFieldValue("registerYear", e.target.value);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>
                  <div className="row mb-4_3">
                    <div className="col-12">
                      <InputGroupAction
                        clasNameInputGroup=""
                        clasNameForm={formik.errors.emissionYear ? "is-invalid" : ""}
                        clasNameInput={formik.errors.emissionYear ? "is-invalid" : ""}
                        type="tel"
                        id="emissionYear"
                        name="emissionYear"
                        placeholder=""
                        value={formik.values.emissionYear}
                        error={formik.errors.emissionYear}
                        text={listText.text_27.mainText}
                        onChange={(e: any) => {
                          if (allownumber.test(e.target.value)) {
                            formik.setFieldValue("emissionYear", e.target.value);
                          }
                        }}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                        srcImage={InfoIcon}
                        titleText=""
                        displayLeftImageTitle=""
                        classTitle=""
                        styleTitle={{
                          color: "#005CB9",
                          fontSize: "12px",
                          cursor: "pointer",
                        }}
                        onClickTitleImgeDiv={() => setOpenInfoButton(true)}
                      ></InputGroupAction>
                    </div>
                  </div>
                  <div className="row mb-4_3">
                    <div className="col-12">
                      <Input
                        className={`form-control ${formik.errors.ocr ? "is-invalid" : ""}`}
                        type="number"
                        id="ocr"
                        name="ocr"
                        placeholder=""
                        value={formik.values.ocr || ""}
                        error={formik.errors.ocr}
                        text={listText.text_28.mainText || ""}
                        onChange={(e: any) => {
                          formik.setFieldValue("ocr", e.target.value.toUpperCase());
                        }}
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-12">
                      <Input
                        className={`form-control ${formik.errors.cic ? "is-invalid" : ""}`}
                        type="number"
                        id="cic"
                        name="cic"
                        placeholder=""
                        value={formik.values.cic || ""}
                        error={formik.errors.cic}
                        text={listText.text_29.mainText || ""}
                        onChange={(e: any) => {
                          formik.setFieldValue("cic", e.target.value.toUpperCase());
                        }}
                        onBlur={formik.handleBlur}
                        src=""
                        disabled={false}
                        style={undefined}
                        displayLabel="dark-gray"
                        htmlFor={""}
                      ></Input>
                    </div>
                  </div>

                  <div className="mb-3">
                    <TitleAndImage
                      srcImage={""}
                      titleText={listText.text_30.mainText}
                      displayLeftImageTitle={""}
                      classTitle={""}
                      styleTitle={{
                        color: "#005CB9",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      onClickTitleImgeDiv={() => setOpenBottonSheet(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer--pin">
        <Button
          text={listText.text_31.mainText}
          divClassName={"w-100 mt-5"}
          buttonClassName={`${showSpinner ? "pe-none button-disabled" : "btn-bg-primary text-white"
            }  w-100 place-content-center align-self-center`}
          spinner={showSpinner}
          //disabled={!formik.isValid || dateError}
          onClick={() => {
            submit();
          }}
          dataBsDismiss={undefined}
        />

        <div className="text-center mt-3">
          <Link
            text={"Seguir más tarde"}
            url={null}
            styleLink={{
              color: "#5A6172",
              lineHeight: "16px",
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={followLaterAction}
            className={""}
          />
        </div>
      </div>
      <BottomSheetMessage
        title={listText.text_32.mainText}
        text={listText.text_33.mainText}
        isOpen={isOpenBottonSheet}
        imageInside={IneModel}
        onClickClose={() => setOpenBottonSheet(false)}
      />

      <BottomSheetMessage
        title={listText.text_27.mainText}
        text={`Si tu INE tiene una marca de agua color verde, naranja y gris debajo de la foto y códigos QR en el reverso, no es necesario completar el campo "Año de Emisión".`}
        isOpen={openInfoButton}
        imageInside={IneGhType}
        onClickClose={() => setOpenInfoButton(false)}
      />

      <MessagesReactModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        textTitle={
          invalidDataBpId ?
            listText.text_38.mainText :
            listText.text_34.mainText
        }
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: invalidDataBpId ?
              listText.text_41.mainText :
              listText.text_37.mainText,
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => {
              functionProcessModal();
            },
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => setModalShow(false)}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph: invalidDataBpId ?
              listText.text_39.mainText :
              listText.text_35.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: invalidDataBpId ?
              listText.text_40.mainText :
              listText.text_36.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default DataConfirmation;
