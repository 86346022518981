import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
// Atoms
import Loader from "../../components/atoms/loader";
// Templates
import MessagesReactModal from "../../components/templates/messages-react-modalV2";
import Top from "../../components/templates/topV2";
// Hooks
import { useRFC } from "../../hooks/useRFC";
import { useApi } from "../../hooks/useApi";
import { useFollowLater } from "../../hooks/useFollowLater";
// Redux 
import { onFiscalFormation } from "../../redux/slices/clientInformation";
import { useRedux } from "../../hooks/useRedux";
// Service
import PERSONALDATA from "../../services/personalData.service";
// Dictionary
import listText from "../../utils/dictionaries/dictionary-fiscal-information-client";
// Amplitude
import { trackAnalyticsEvent } from "../../utils/amplitude";
import { AmplitudEventName } from "../../utils/amplitude/types";
// Components 
import { ConfirmAddress } from "./confirm-address";
import { ConfirmationSat } from "./confirmation";
import { FiscalInformationClient } from "./fiscal-information";
// Schema
import validationSchema from "./schema";
import { Identify, identify } from "@amplitude/analytics-browser";

export type TTypeTab = "contact-information" | "confirm-address" | "alert-data";

const { postFiscalInformation } = new PERSONALDATA();

const InformationClient = () => {
  const [isModaError, setIsModalError] = useState<boolean>(false);
  const [isLoadingRedirect, setLoadingRedirect] = useState(false);
  const navigate = useNavigate();
  const { onClickContinue } = useFollowLater();
  const { dataRfcByPerson, errorRfcByPerson, isLoadingRfcByPerson } = useRFC();
  const {
    data: {
      clientInformation,
      clientPersist: { uuid },
      dataPersist,
    },
    dispatch,
  } = useRedux();

  const {
    data: dataFiscalInformation = { validateSAT: undefined },
    error: errorFiscalInformation,
    execute: executeFiscalInformation,
    isLoading: isLoadingFiscalInformation,
  } = useApi(postFiscalInformation);

  const [view, setView] = useState<TTypeTab>("contact-information");

  const formik = useFormik({
    initialValues: {
      valueRFC: clientInformation.fiscalFormation.valueRFC,
      homoclave: clientInformation.fiscalFormation.homoclave,
      addressMatch: clientInformation.fiscalFormation.addressMatch,
      regimenFiscal: clientInformation.fiscalFormation.regimenFiscal,
      fiscalRegimeKey: clientInformation.fiscalFormation.fiscalRegimeKey,
      confirmAddress: {
        zipCode: clientInformation.fiscalFormation.confirmAddress?.zipCode
          ? clientInformation.fiscalFormation.confirmAddress.zipCode
          : "",
        state: clientInformation.fiscalFormation.confirmAddress?.state
          ? clientInformation.fiscalFormation.confirmAddress.state
          : "",
        stateId: clientInformation.fiscalFormation.confirmAddress?.stateId
          ? clientInformation.fiscalFormation.confirmAddress.stateId
          : "",
        township: clientInformation.fiscalFormation.confirmAddress?.township
          ? clientInformation.fiscalFormation.confirmAddress.township
          : "",
        townshipId: clientInformation.fiscalFormation.confirmAddress?.townshipId
          ? clientInformation.fiscalFormation.confirmAddress.townshipId
          : "",
        suburbs: clientInformation.fiscalFormation.confirmAddress?.suburbs
          ? clientInformation.fiscalFormation.confirmAddress.suburbs
          : "",
        street: clientInformation.fiscalFormation.confirmAddress?.street
          ? clientInformation.fiscalFormation.confirmAddress.street
          : "",
        outsideNumber: clientInformation.fiscalFormation.confirmAddress?.outsideNumber
          ? clientInformation.fiscalFormation.confirmAddress.outsideNumber
          : "",
        internalNumber: clientInformation.fiscalFormation.confirmAddress?.internalNumber
          ? clientInformation.fiscalFormation.confirmAddress.internalNumber
          : "",
      },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
  });

  const onOk = () => {
    if (formik.values.addressMatch === "no") {
      dispatch(onFiscalFormation({ ...formik.values }));
      return setView("confirm-address");
    } else {
      onClickContinue({
        data: { fiscalFormation: formik.values, dataPersist },
        processId: uuid,
        resource: "FiscalInformationClient",
        step: "CONTINUE",
      });
      trackAnalyticsEvent(
        AmplitudEventName.Capturar_RFC_N4, {
        "RFC": `${formik.values.valueRFC}${formik.values.homoclave}`,
        "Régimen_Fiscal": formik.values.regimenFiscal,
        "Domicilio_Coincide": "SI",
      });
      
      const userProperties = new Identify();
      userProperties.set('RFC', `${formik.values.valueRFC}${formik.values.homoclave}`);
      identify(userProperties);

      executeFiscalInformation({ fiscalFormation: formik.values, processId: uuid });
      dispatch(onFiscalFormation({ ...formik.values }));
    }
  };

  const onOkConfirmAddress = () => {
    dispatch(onFiscalFormation({ ...formik.values }));
    executeFiscalInformation({ fiscalFormation: formik.values, processId: uuid });
    onClickContinue({
      data: { fiscalFormation: formik.values, dataPersist },
      processId: uuid,
      resource: "FiscalInformationClient",
      step: "CONTINUE",
    });
    trackAnalyticsEvent(
      AmplitudEventName.Capturar_RFC_N4, {
      "RFC": `${formik.values.valueRFC}${formik.values.homoclave}`,
      "Regimen_Fiscal": formik.values.regimenFiscal,
      "Domicili_Coincide": "NO",
    }
    )
  };

  const onClickButtonLink = () => {
    navigate("/FollowLater", {
      state: {
        processId: uuid,
        resource: "FiscalInformationClient",
        step: "CONTINUE_AFTER",
        data: { fiscalFormation: formik.values, dataPersist },
      },
    });
    dispatch(onFiscalFormation({ ...formik.values }));
  };

  const onConfirmationSat = () => {
    navigate("/LaborInformation")

  }

  useEffect(() => {
    if (
      !dataFiscalInformation["validateSAT"] &&
      errorFiscalInformation &&
      !isLoadingFiscalInformation
    ) {
      setIsModalError(true);
    } else {
      if (dataFiscalInformation["validateSAT"] === false && !isLoadingFiscalInformation) {
        trackAnalyticsEvent(
          AmplitudEventName.Identificar_información_fiscal_no_coincide_SAT, {
          "RFC": `${formik.values.valueRFC}${formik.values.homoclave}`,
          "Regimen_Fiscal": formik.values.regimenFiscal,
          "Codigo_Postal": formik.values.confirmAddress.zipCode,
        });
        return setView("alert-data");
      } else if (dataFiscalInformation["validateSAT"] === true && !isLoadingFiscalInformation) {
        navigate("/LaborInformation");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFiscalInformation, errorFiscalInformation && !isLoadingFiscalInformation]);

  useEffect(() => {
    if (dataRfcByPerson.rfc && !isLoadingRfcByPerson && !errorRfcByPerson) {
      formik.setFieldValue("valueRFC", dataRfcByPerson.rfc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRfcByPerson, isLoadingRfcByPerson, errorRfcByPerson]);

  return (
    <>
      {isLoadingFiscalInformation ? (
        <Loader />
      ) : (
        <>
          <div className="content-container">
            <Top
              titleHeader={listText.top.titleHeader}
              subTitle={!["alert-data"].includes(view) ? listText.top.subTitle : ""}
              displayLeftImageTitle={
                !["alert-data", "contact-information"].includes(view) ? "d-block" : "d-none"
              }
              displayProgressBar={["alert-data"].includes(view) ? "d-none" : "d-block"}
              classHeader={"mt-2"}
              classProgressBar={"mt-3"}
              valueProgressBar={"50%"}
              onClickGoBackIcon={() => setView("contact-information")}
            />
            {view === "contact-information" && (
              <FiscalInformationClient
                isLoadingFiscalInformation={isLoadingFiscalInformation}
                onClickButtonLink={onClickButtonLink}
                onOk={onOk}
                formik={formik}
              />
            )}
            {view === "confirm-address" && (
              <ConfirmAddress
                isLoadingFiscalInformation={isLoadingFiscalInformation}
                setView={onOkConfirmAddress}
                formik={formik}
              />
            )}
            {view === "alert-data" && (
              <ConfirmationSat
                isLoadingFiscalInformation={isLoadingFiscalInformation}
                setView={onConfirmationSat}
              />
            )}
          </div>
        </>
      )}

      <MessagesReactModal
        show={isModaError}
        onHide={() => {
          setTimeout(() => {
            setLoadingRedirect(true);
            setIsModalError(false);
            setLoadingRedirect(false);
          }, 1000);
          window.location.reload();
        }}
        textTitle={"¡Ups lo sentimos!"}
        listButtonsModal={[
          {
            text: "Reintentar",
            spinner: isLoadingRedirect,
            onClick: () => {
              setTimeout(() => {
                setLoadingRedirect(true);
                setIsModalError(false);
                setLoadingRedirect(false);
              }, 1000);
              window.location.reload();
            },
          },
        ]}
        onClickClose={() => {
          setTimeout(() => {
            setLoadingRedirect(true);
            setIsModalError(false);
            setLoadingRedirect(false);
          }, 1000);
          window.location.reload();
        }}
        listParagraphs={[
          {
            textParagraph:
              "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
          },
          {
            textParagraph: "Vuelve a intentar si el error persiste.",
          },
        ]}
      />
    </>
  );
};

export default InformationClient;
